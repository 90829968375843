import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  static values = { shortened: String }

  success(e) {
    const data = e.detail[0].body.innerHTML;
    this.formTarget.innerHTML = data;
  }

  error(e) {
    this.formTarget.innerHTML = '<p class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-center">an error occurred, so sorry</p>';
  }

  copy() {
    navigator.clipboard.writeText(this.shortenedValue);
  }
}
